const toggleAccessibility = (element: Element | null): void => {
  if (!element || !(element instanceof Element)) {
    return;
  }

  if (!element.hasAttribute('aria-expanded')) {
    element.setAttribute('aria-expanded', String(false));
    return;
  }

  if (element.getAttribute('aria-expanded') === 'true') {
    element.setAttribute('aria-expanded', String(false));
  } else {
    element.setAttribute('aria-expanded', String(true));
  }
};

const trapFocus = (e: KeyboardEvent): void => {
  const currentTarget = e.currentTarget;
  if (!currentTarget || !(currentTarget instanceof HTMLElement)) {
    return;
  }

  const focusable = currentTarget?.querySelectorAll<HTMLElement>(
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
  );

  const isHidden = (el: HTMLElement) => {
    const style = window.getComputedStyle(el);
    return style.display === 'none';
  };

  const filteredFocusableElements = Array.from(focusable).filter((el) => !isHidden(el));

  const firstFocusableElement = filteredFocusableElements[0];
  const lastFocusableElement = filteredFocusableElements[filteredFocusableElements.length - 1];

  if (e instanceof KeyboardEvent) {
    if (e.key === 'Tab') {
      if (e.shiftKey && e.target === firstFocusableElement) {
        e.preventDefault();
        lastFocusableElement?.focus();
      } else if (!e.shiftKey && e.target === lastFocusableElement) {
        e.preventDefault();
        firstFocusableElement?.focus();
      }
    }
  }
};

const openDrawer = (trigger: Element | null): void => {
  if (!trigger || !(trigger instanceof Element)) {
    return;
  }
  const target = document.getElementById(<string>trigger.getAttribute('aria-controls'));

  const buttonClose = target?.querySelector('.drawer__close');
  window._modalOpenedBy = trigger;

  target?.classList.add('is-active');
  document.documentElement.style.overflow = 'hidden';
  toggleAccessibility(trigger);
  setTimeout(function () {
    target?.classList.add('is-visible');
    if (buttonClose instanceof HTMLElement) {
      buttonClose?.focus();
    }
  }, 50);

  target?.addEventListener('keydown', trapFocus);
};

const closeDrawer = (event: Element): void => {
  const closestParent = event.closest('[data-drawer-target]'),
    childrenTrigger = document.querySelector('[aria-controls="' + closestParent?.id + '"');
  closestParent?.classList.remove('is-visible');
  document.documentElement.style.overflow = '';
  toggleAccessibility(childrenTrigger);
  setTimeout(function () {
    closestParent?.classList.remove('is-active');
  }, 350);

  const openingButton = window._modalOpenedBy;

  if (openingButton && openingButton instanceof HTMLElement) {
    openingButton.focus();
  }
};

const handleDrawerKeyEvent = (event: Event | KeyboardEvent): void => {
  if (event instanceof KeyboardEvent && event.target instanceof Element) {
    if (event.key === 'Escape') {
      document.querySelectorAll('[data-drawer-target].is-active').forEach(closeDrawer);
    }

    if (event.target.hasAttribute('data-drawer-trigger') && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
      openDrawer(event.target);
      return;
    }
  }
};

export function initDrawers(): void {
  document.addEventListener('keydown', handleDrawerKeyEvent);

  document.addEventListener('click', (e) => {
    if (!(e.target instanceof HTMLElement) && !(e.target instanceof Element)) {
      return;
    }

    if (e.target.hasAttribute('data-drawer-trigger')) {
      e.preventDefault();
      openDrawer(e.target);
      return;
    }

    if (e.target.hasAttribute('data-drawer-close')) {
      e.preventDefault();
      closeDrawer(e.target);
      return;
    }
  });
}
